<template>
<div class="wellCome">
  <img :src="logo.bigLogo" />
</div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["logo"]),
  },
}
</script>

<style lang="scss" scoped>
.wellCome {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
  overflow: auto;

  img {
    width: 200px;
  }
}
</style>
